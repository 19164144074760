import {
  Client,
  Conversation,
  Media,
  Message,
  Paginator,
  Participant,
} from "@twilio/conversations";
import axios, { AxiosError } from "axios";

import {
  CONVERSATION_MESSAGES,
  CONVERSATION_PAGE_SIZE,
  PARTICIPANT_MESSAGES,
  UNEXPECTED_ERROR_MESSAGE,
} from "../constants";
import { getSdkMessageObject } from "../conversations-objects";
import { successNotification, unexpectedErrorNotification } from "../helpers";
import {
  MessageStatus,
  ReduxMessage,
} from "../store/reducers/messageListReducer";
import { NotificationsType } from "../store/reducers/notificationsReducer";
import { ReduxParticipant } from "../store/reducers/participantsReducer";

const API_DEV_TWILIO_URL =
  "https://pyam5n8489.execute-api.us-east-1.amazonaws.com/dev/v1";

const API_TWILIO_URL =
  "https://ci0gl14d3k.execute-api.us-east-1.amazonaws.com/prd/v1";
const API_MDX_URL = "https://partner.medexapi.com";

type ParticipantResponse = ReturnType<typeof Conversation.prototype.add>;

export async function addConversation(
  name: string,
  updateParticipants: (participants: Participant[], sid: string) => void,
  client?: Client,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<Conversation> {
  if (name.length > 0 && client !== undefined) {
    try {
      const conversation = await client.createConversation({
        friendlyName: name,
      });
      await conversation.join();

      const participants = await getConversationParticipants(conversation);
      updateParticipants(participants, conversation.sid);

      successNotification({
        message: CONVERSATION_MESSAGES.CREATED,
        addNotifications,
      });

      return conversation;
    } catch (e) {
      unexpectedErrorNotification(addNotifications);

      return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
  }
  return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
}

export async function sendMessage(
  obj: {
    template: string;
    message: any;
    partner: string;
    conversationSid: string;
  },
  addNotifications?: (notifications: NotificationsType) => void
): Promise<any> {
  const requestAddress = API_TWILIO_URL;

  try {
    const res = axios.post(`${requestAddress}/send/conversation/template`, obj);

    return res;
  } catch (e) {
    unexpectedErrorNotification(addNotifications);

    return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
  }
}

export async function createConversation(
  obj: {
    name: string;
    number: string;
    partner: string;
  },
  addNotifications?: (notifications: NotificationsType) => void
): Promise<any> {
  const requestAddress = API_TWILIO_URL;
  if (obj.name.length && obj.number.length && obj.partner.length) {
    try {
      const res = await axios.post(`${requestAddress}/start/conversation`, obj);

      if (res.data.error) {
        return Promise.reject(res.data.data as any);
      }

      successNotification({
        message: res.data.message || CONVERSATION_MESSAGES.CREATED,
        addNotifications,
      });

      return res.data.conversationSid as string;
    } catch (e) {
      if (e == "Error: Network Error") {
        return Promise.reject({ status: 502 });
      } else if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;

        const response = axiosError.response;
        return Promise.reject(response as any);
      }

      unexpectedErrorNotification(addNotifications);
    }
  }
  return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
}

export async function disableConversation(
  sid: string,
  partner: string,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<Conversation> {
  const requestAddress = API_TWILIO_URL;
  if (sid.length) {
    try {
      const res = await axios.put(
        `${requestAddress}/disable/conversation/${sid}`,
        {
          partner,
        }
      );

      successNotification({
        message: res?.data?.message || CONVERSATION_MESSAGES.DISABLED_CONVO,
        addNotifications,
      });

      return res.data.sid;
    } catch (e) {
      unexpectedErrorNotification(addNotifications);
      return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
  }
  return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
}

export async function fetchClosedConvos(params: {
  limit: number;
  query: string;
}): Promise<any> {
  const requestAddress = API_TWILIO_URL;
  if (params.query.length) {
    try {
      const res = await axios.get(
        `${requestAddress}/twilio/search/disabled?${params.query}&limit=${
          params.limit
        }&partner=${localStorage.getItem("partner")}`
      );

      return res.data;
    } catch (e) {
      return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
  }
  return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
}

export async function addParticipant(
  name: string,
  proxyName: string,
  chatParticipant: boolean,
  convo?: Conversation,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<ParticipantResponse> {
  if (convo === undefined) {
    return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
  }

  if (chatParticipant && name.length > 0) {
    try {
      const result = await convo.add(name);
      if (!chatParticipant)
        successNotification({
          message: PARTICIPANT_MESSAGES.ADDED,
          addNotifications,
        });
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  if (!chatParticipant && name.length > 0 && proxyName.length > 0) {
    try {
      const result = await convo.addNonChatParticipant(proxyName, name, {
        friendlyName: name,
      });
      successNotification({
        message: PARTICIPANT_MESSAGES.ADDED,
        addNotifications,
      });

      return result;
    } catch (e) {
      unexpectedErrorNotification(addNotifications);

      return Promise.reject(e);
    }
  }
  return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
}

export async function getMsgTemplates(partner: string): Promise<any> {
  const requestAddress = API_TWILIO_URL;

  if (!requestAddress) {
    return Promise.reject(
      "A URL do serviço de modelos de mensagem não está configurada."
    );
  }

  try {
    const res = await axios.get(
      `${requestAddress}/twilio/compare/templates/${partner}?PageSize=800&Page=0`,
      {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(`Erro ao buscar modelos de mensagem`);
  }
}
export async function getToken(
  username: string,
  password: string
): Promise<string> {
  const requestAddress = API_MDX_URL;

  if (!requestAddress) {
    return Promise.reject("A URL do serviço de login não está configurada.");
  }

  try {
    const res = await axios.post(`${requestAddress}/user/login`, {
      username: username,
      password: password,
    });
    const sortPartners = res?.data?.user?.clients?.sort(
      (a: string, b: string) => {
        return a.localeCompare(b);
      }
    );
    localStorage.setItem("partners", sortPartners);
    localStorage.setItem("token", res.data.idToken);
    localStorage.setItem("name", res.data.user.name);

    return res.data.idToken;
  } catch (error) {
    return Promise.reject(`Usuário ou senha inválidos.`);
  }
}

export async function getTokenFromTwilio(
  username: string,
  partner: string,
  name: string
): Promise<string> {
  const requestAddress = API_TWILIO_URL;

  if (!requestAddress) {
    return Promise.reject(
      "A URL do serviço de geração de token não está configurada."
    );
  }

  try {
    const response = await axios.post(`${requestAddress}/conversations/token`, {
      username: username,
      partner: partner,
      name: name,
    });
    localStorage.setItem("tokenTwilio", response.data.token);
    localStorage.setItem("partnerPhone", response.data.phone);
    return response.data.token as unknown as string;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      return Promise.reject("Erro de autenticação.");
    }
    if (axios.isAxiosError(error) && error.response?.status === 500) {
      return Promise.reject("Parceiro não possui token na Twilio.");
    }
    return Promise.reject(`Erro recebido da Twilio`);
  }
}

export async function getUsersFromTwilio(partner: string): Promise<string> {
  const requestAddress = API_TWILIO_URL;
  if (!requestAddress) {
    return Promise.reject("A URL do serviço de busca não está configurada.");
  }

  try {
    const response = await axios.get(
      `${requestAddress}/twilio/users/${partner}`
    );
    localStorage.setItem("usersTwilio", response.data.items);
    return response.data.items as unknown as any;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      return Promise.reject("Erro de autenticação.");
    }
    if (axios.isAxiosError(error) && error.response?.status === 500) {
      return Promise.reject("Parceiro não possui token na Twilio.");
    }
    return Promise.reject(`Erro recebido da Twilio`);
  }
}

export async function getMessageStatus(
  message: ReduxMessage,
  channelParticipants: ReduxParticipant[]
): Promise<{
  [MessageStatus.Delivered]?: number;
  [MessageStatus.Read]?: number;
  [MessageStatus.Failed]?: number;
  [MessageStatus.Sending]?: number;
}> {
  // FIXME should be: return statuses[message.sid];
  // after this modification:
  // message.on("updated", ({ message, updateReasons }) => {
  // if reason includes "deliveryReceipt" {
  //   // paginate detailed receipts
  //   const receipts = await message.getDetailedDeliveryReceipts(); // paginated backend query every time
  // }
  // });

  const statuses = {
    [MessageStatus.Delivered]: 0,
    [MessageStatus.Read]: 0,
    [MessageStatus.Failed]: 0,
    [MessageStatus.Sending]: 0,
  };

  if (message.index === -1) {
    return Promise.resolve({
      ...statuses,
      [MessageStatus.Sending]: 1,
    });
  }

  // channelParticipants.forEach((participant) => {
  //   if (
  //     participant.identity == localStorage.getItem("username") ||
  //     participant.type !== "chat"
  //   ) {
  //     return;
  //   }

  //   if (
  //     participant.lastReadMessageIndex &&
  //     participant.lastReadMessageIndex >= message.index
  //   ) {
  //     statuses[MessageStatus.Read] += 1;
  //   } else if (participant.lastReadMessageIndex !== -1) {
  //     // statuses[MessageStatus.Delivered] += 1;
  //   }
  // });

  if (message.aggregatedDeliveryReceipt) {
    const sdkMessage = getSdkMessageObject(message);
    const receipts = await sdkMessage.getDetailedDeliveryReceipts(); // paginated backend query every time
    receipts.forEach((receipt) => {
      const errorCodeAsInt = parseInt(receipt.errorCode as string, 10);

      if (receipt.status === "read") {
        statuses[MessageStatus.Read] += 1;
      }

      if (receipt.status === "delivered") {
        statuses[MessageStatus.Delivered] += 1;
      }

      if (receipt.status === "failed" || receipt.status === "undelivered") {
        statuses[MessageStatus.Failed] += errorCodeAsInt;
      }
      if (receipt.status === "sent" || receipt.status === "queued") {
        statuses[MessageStatus.Sending] += 1;
      }
    });
  }

  return statuses;
}

export const getConversationParticipants = async (
  conversation: Conversation
): Promise<Participant[]> => {
  const response = await conversation.getParticipants();

  return response;
};

export const removeParticipant = async (
  conversation: Conversation,
  participant: Participant,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<void> => {
  try {
    await conversation.removeParticipant(participant);
    successNotification({
      message: PARTICIPANT_MESSAGES.REMOVED,
      addNotifications,
    });
  } catch {
    unexpectedErrorNotification(addNotifications);
    return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getBlobFile = async (
  media: Media,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<Blob> => {
  try {
    const url = await getFileUrl(media);
    const response = await fetch(url);
    return response.blob();
  } catch (e) {
    unexpectedErrorNotification(addNotifications);
    return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getFileUrl = async (media: Media): Promise<string> => {
  return await media.getContentTemporaryUrl().then();
};

export const getMessages = async (
  conversation: Conversation
): Promise<Paginator<Message>> => {
  const response = await conversation.getMessages(CONVERSATION_PAGE_SIZE);
  return response;
};
