import { Button } from "@twilio-paste/button";
import { SendIcon } from "@twilio-paste/icons/esm/SendIcon";
import { Box } from "@twilio-paste/core";

interface SendMessageButtonProps {
  message: string;
  onClick: () => void;
}

const SendMessageButton: React.FC<SendMessageButtonProps> = (
  props: SendMessageButtonProps
) => {
  return (
    <Box display="flex" alignItems="flex-end" paddingRight="space20">
      <Button
        variant="primary_icon"
        onClick={() => {
          props.onClick();
        }}
      >
        <SendIcon decorative={true} title="Enviar mensagem" size="sizeIcon50" />
      </Button>
    </Box>
  );
};

export default SendMessageButton;
