import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { Box, Button } from "@twilio-paste/core";
import { AttachIcon } from "@twilio-paste/icons/esm/AttachIcon";
import { DeleteIcon } from "@twilio-paste/icons/esm/DeleteIcon";

import { FileIcon } from "@twilio-paste/icons/esm/FileIcon";
import { Text } from "@twilio-paste/text";
import { useTheme } from "@twilio-paste/theme";
import { Client } from "@twilio/conversations";
import { AppState, actionCreators } from "../../store";

import { MAX_FILE_SIZE, UNEXPECTED_ERROR_MESSAGE } from "../../constants";
import { getSdkConversationObject } from "../../conversations-objects";
import { getTypingMessage, unexpectedErrorNotification } from "../../helpers";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import { ReduxMessage } from "../../store/reducers/messageListReducer";
import MessageInput from "./MessageInput";
import SendMessageButton from "./SendMessageButton";
import { sendMessage } from "../../services/api";

interface SendMessageProps {
  convoSid: string;
  client: Client;
  messages: ReduxMessage[];
  convo: ReduxConversation;
  typingData: string[];
}

const MessageInputField: React.FC<SendMessageProps> = (
  props: SendMessageProps
) => {
  const [templateObj, setTemplateObj] = useState({} as any);
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [isTemplate, setIsTemplate] = useState(false);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  // needed to clear input type=file
  const [filesInputKey, setFilesInputKey] = useState<string>("input-key");

  const theme = useTheme();
  const typingInfo = getTypingMessage(props.typingData);

  const dispatch = useDispatch();
  const { upsertMessages, addNotifications, addAttachment } =
    bindActionCreators(actionCreators, dispatch);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleAttachFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    setMessage("");
    setFiles([]);
    setFilesInputKey(Date.now().toString());
  }, [props.convo.sid]);

  useEffect(() => {
    if (!files.length) {
      setFilesInputKey(Date.now().toString());
    }
  }, [files]);

  const sdkConvo = useMemo(
    () => getSdkConversationObject(props.convo),
    [props.convo.sid]
  );

  const participants =
    useSelector((state: AppState) => state.participants)[props.convo.sid] ?? [];

  const onFilesChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files: assets } = event.target;
    if (!assets?.length) {
      return;
    }

    const validFiles = Array.from(assets).filter(
      ({ size }) => size < MAX_FILE_SIZE + 1
    );

    if (validFiles.length < assets.length) {
      // TODO: show error
    }

    setFiles([...files, ...validFiles]);
  };

  const checkIamOwner = () => {
    const username = localStorage.getItem("username") ?? "";
    const attributes =
      typeof sdkConvo.attributes === "object" ? sdkConvo.attributes : null;

    return (
      attributes !== null &&
      typeof attributes === "object" &&
      !(attributes as Record<string, any>).isOwner?.includes(username)
    );
  };

  const onFileRemove = (file: string) => {
    const fileIdentityArray = file.split("_");
    const fileIdentity = fileIdentityArray
      .slice(0, fileIdentityArray.length - 1)
      .join();
    const existentFiles = files.filter(
      ({ name, size }) =>
        name !== fileIdentity &&
        size !== Number(fileIdentityArray[fileIdentityArray.length - 1])
    );

    setFiles(existentFiles);
  };

  const sendTemplateMessage = async () => {
    const { convo } = props;
    const payload = {
      template: templateObj.templateId,
      message: templateObj.variables,
      partner: localStorage.getItem("partner") ?? "",
      conversationSid: convo.sid,
    };

    try {
      await sendMessage(payload, addNotifications);
      setMessage("");
      setIsTemplate(false);
      setTemplateObj({} as any);
      setFiles([]);
    } catch (e) {
      setMessage("");
      setIsTemplate(false);
      setTemplateObj({} as any);
      setFiles([]);
      unexpectedErrorNotification(addNotifications);
      return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
  };

  const onMessageSend = async () => {
    if (message.length == 0 && files.length == 0) {
      return;
    }

    if (isTemplate) {
      sendTemplateMessage();
      return;
    }

    const { convo, client } = props;
    const sdkConvo = getSdkConversationObject(convo);

    const newMessageBuilder = sdkConvo.prepareMessage().setBody(message);

    for (const [key, file] of files.entries()) {
      const fileData = new FormData();
      fileData.set(file.name, file, file.name);
      newMessageBuilder.addMedia(fileData);
    }
    setMessage("");
    setIsTemplate(false);
    setFiles([]);
    const messageIndex = await newMessageBuilder.build().send();

    try {
      await sdkConvo.advanceLastReadMessageIndex(messageIndex ?? 0);
    } catch (e) {
      unexpectedErrorNotification(addNotifications);
      return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
  };

  return (
    <Box
      display="flex"
      flexBasis="60px"
      flexGrow={10}
      flexDirection="column"
      borderTopStyle="solid"
      borderTopWidth="borderWidth10"
      style={{
        borderTopColor: theme.borderColors.colorBorderWeak,
        backgroundColor: theme.backgroundColors.colorBackgroundBody,
      }}
    >
      <Box
        paddingBottom="space20"
        paddingTop="space50"
        paddingLeft="space150"
        hidden={!props.typingData.length}
      >
        <Text as="p" color="colorTextIcon">
          {typingInfo}
        </Text>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        height="100%"
        flexGrow={10}
        paddingBottom="space30"
        paddingTop="space40"
      >
        <Box display="flex" justifyContent="center" alignItems="flex-end">
          <Button variant="secondary_icon" onClick={handleAttachFile}>
            <AttachIcon
              decorative={true}
              title="Anexar arquivo"
              size="sizeIcon40"
            />
            <input
              ref={fileInputRef}
              id="file-input"
              key={filesInputKey}
              disabled={checkIamOwner()}
              type="file"
              style={{ display: "none" }}
              onChange={onFilesChange}
            />
          </Button>
          <Button
            variant="secondary_icon"
            onClick={() => setIsTemplateModalOpen((a) => !a)}
          >
            <FileIcon
              decorative={true}
              title="Modelos de mensagem"
              size="sizeIcon40"
            />
          </Button>
        </Box>
        <Box display="flex" flexGrow={10} alignItems="flex-end">
          <MessageInput
            disabled={checkIamOwner() || isTemplate}
            disabledTemplate={checkIamOwner()}
            setIsModalOpen={setIsTemplateModalOpen}
            isModalOpen={isTemplateModalOpen}
            assets={files}
            message={message}
            participants={participants}
            onChange={(e: any, template) => {
              sdkConvo.typing();
              setIsTemplate(template);
              if (typeof e === "string") {
                setMessage(e);
              } else {
                setMessage(e.text);
                setTemplateObj(e);
              }
            }}
            onKeyPress={async (e) => {
              if (e.key === "Enter" && message) {
                await onMessageSend();
              }
            }}
            onFileRemove={onFileRemove}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="flex-end"
        >
          {message && isTemplate && (
            <Box padding="space10">
              <Button
                variant="destructive_icon"
                onClick={() => {
                  setMessage("");
                  setIsTemplate(false);
                }}
              >
                <DeleteIcon
                  decorative={true}
                  title="Limpar mensagem"
                  size="sizeIcon40"
                />
              </Button>
            </Box>
          )}

          {message || files.length ? (
            <SendMessageButton message={message} onClick={onMessageSend} />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default MessageInputField;
