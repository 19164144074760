import { ActionType } from "../action-types";
import { Action } from "../actions";

export type ReduxTemplate = {
  account_sid: string;
  friendly_name: string;
  medex_name: string;
  sid: string;
  types: string;
  variables: {
    [key: string]: string;
  };
};

export type TemplatesType = ReduxTemplate[];

const initialState: TemplatesType = [];

const reducer = (
  state: TemplatesType = initialState,
  action: Action
): TemplatesType => {
  switch (action.type) {
    case ActionType.SET_MSG_TEMPLATES:
      const { templates } = action.payload;

      return templates;
    default:
      return state;
  }
};

export default reducer;
