import React, { useEffect, useMemo, useState } from "react";
import { Box, Button } from "@twilio-paste/core";
import { CloseIcon } from "@twilio-paste/icons/esm/CloseIcon";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { ReduxTemplate } from "../../store/reducers/msgTemplatesReducer";

type TemplateListProps = {
  setMessage(t: { text: string; variables: any; templateId: string }): void;
  disabled?: boolean;
  isModalOpen: boolean;
  setShowModal: (i: boolean) => void;
};

const TemplateList: React.FC<TemplateListProps> = (
  props: TemplateListProps
) => {
  const templates = useSelector((state: AppState) => state.templates);
  const [showSettingVariables, setShowSettingVariables] = useState(false);

  const [template, setTemplate] = useState<ReduxTemplate>({
    account_sid: "",
    friendly_name: "",
    medex_name: "",
    sid: "",
    types: "",
    variables: {},
  });

  const [numInputs, setNumInputs] = useState(0);

  const [filteredTemplates, setFilteredTemplates] = useState<ReduxTemplate[]>(
    []
  );

  const countPlaceholders = (str: string) => {
    setNumInputs((str.match(/\{\{.*?\}\}/g) || []).length);
  };

  const handleSubmit = () => {
    const messageObj = {
      ...replaceValues(template.types),
      templateId: template.sid,
    };
    props.setMessage(messageObj);
    setShowSettingVariables(false);
  };

  const replaceValues = (msg: string) => {
    const inputs = document.querySelectorAll(
      ".input-substitute"
    ) as NodeListOf<HTMLInputElement>;

    const result: { [key: number]: string } = {};

    inputs.forEach((input) => {
      const id = input.getAttribute("id");
      const idNum = parseInt(id?.replace("input-", "") || "");
      const value = input.value;
      if (!isNaN(idNum)) {
        result[idNum] = value || "";
        msg = msg.replace(`{{${idNum}}}`, value || "");
      }
    });

    return { variables: result, text: msg };
  };

  const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value;

    const filtered = templates.filter((t) => {
      return t.medex_name.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredTemplates(filtered);
  };

  const replacePlaceholders = (str: string) => {
    const variables = template.variables;
    const inputIds = Array.from(
      { length: numInputs },
      (_, i) => `input-${i + 1}`
    );
    const replaced = str.replace(/\{\{(.*?)\}\}/g, () => {
      const id = inputIds.shift();
      const varNumber = id?.replace("input-", "") || "";
      const placeholder = variables[varNumber] || "";
      return `<input id="${id}" placeholder="${placeholder}" type="text" class="input-substitute bg-gray-300 px-1" style="margin: 4px" />`;
    });
    return (
      <div
        className="text-gray-700"
        dangerouslySetInnerHTML={{ __html: replaced }}
      />
    );
  };

  useEffect(() => {
    setFilteredTemplates(templates);
  }, []);

  const list = useMemo(() => {
    return filteredTemplates.map((t, index) => (
      <Button
        variant="inverse_link"
        key={index}
        onClick={() => {
          setTemplate(t);
          countPlaceholders(t.types);
          setShowSettingVariables(true);
          props.setShowModal(false);
        }}
      >
        <TemplateItem {...t} />
      </Button>
    ));
  }, [filteredTemplates]);

  return (
    <>
      {props.isModalOpen && (
        <Box
          bottom="65px"
          left="365px"
          className="absolute  rounded-xl bg-gray-100 shadow-2xl  w-[450px] p-4"
          style={{ maxHeight: "60vh", overflow: "hidden" }}
        >
          <div className="flex align-center justify-between mb-2">
            <h2 className=" text-lg font-bold mb-0 ">Modelos de mensagem</h2>
            <Button
              variant="secondary_icon"
              onClick={() => props.setShowModal(false)}
            >
              <CloseIcon decorative title="Fechar" size="sizeIcon40" />
            </Button>
          </div>

          <input
            type="text"
            id="search-input"
            autoComplete="false"
            autoSave="false"
            placeholder="Pesquise pelo título do modelo"
            style={{
              border: "1px solid #8891AA",
              padding: "8px 12px",
              height: "36px",
              margin: "0px",
              marginBottom: "12px",
              borderRadius: "4px",
              width: "100%",
            }}
            onChange={(e) => handleSearch(e as any)}
          />
          <Box
            display="flex"
            flexDirection="column"
            height="35vh"
            overflowY="auto"
          >
            {list}
          </Box>
        </Box>
      )}
      {showSettingVariables && (
        <Box
          bottom="65px"
          left="365px"
          className="absolute rounded-xl bg-gray-100 shadow-2xl w-[650px] p-4"
          style={{ zIndex: 6 }}
        >
          <div className="flex align-center justify-between mb-2">
            <h2 className=" text-lg font-bold ">
              Preenchimento de variáveis do modelo
            </h2>

            <Button
              variant="secondary_icon"
              onClick={() => setShowSettingVariables(false)}
            >
              <CloseIcon decorative title="Fechar" size="sizeIcon40" />
            </Button>
          </div>

          <p className="font-bold text-gray-700 mb-4">
            {template.medex_name || template.friendly_name.replaceAll("_", " ")}
            :
          </p>

          {replacePlaceholders(template.types)}

          <div className="flex justify-end gap-4 mt-5 pr-1">
            <Button
              variant="secondary"
              onClick={() => {
                props.setShowModal(true);
                setShowSettingVariables(false);
              }}
            >
              Voltar para modelos
            </Button>
            <Button variant="primary" onClick={() => handleSubmit()}>
              Prosseguir
            </Button>
          </div>
        </Box>
      )}
    </>
  );
};
const TemplateItem: React.FC<ReduxTemplate> = ({
  friendly_name,
  medex_name,
}) => {
  return (
    <div className="text-gray-900 font-medium">
      {medex_name || friendly_name.replaceAll("_", " ")}
    </div>
  );
};
export default TemplateList;
