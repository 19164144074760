import { Box } from "@twilio-paste/core";
import { KeyboardEventHandler, useLayoutEffect, useState } from "react";
// import { ReduxParticipant } from "../../store/reducers/participantsReducer";
import MessageFile from "./MessageFile";
import TemplateList from "./TemplatesList";

interface MessageInputProps {
  message: string;
  disabled: boolean;
  disabledTemplate: boolean;
  onChange: (
    msgObj: { variables: any; text: string; templateId: string } | string,
    isTemplate: boolean
  ) => void;
  onKeyPress: KeyboardEventHandler<HTMLTextAreaElement>;
  onFileRemove: (file: string) => void;
  assets: File[];
  participants: unknown[];
  setIsModalOpen: (i: boolean) => void;
  isModalOpen: boolean;
}

const MessageInput: React.FC<MessageInputProps> = (
  props: MessageInputProps
) => {
  const [cursorPosition, setCursorPostions] = useState<number>(0);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      // Lógica para enviar a mensagem
      props.onKeyPress(e);
    }
  };

  return (
    <Box display="flex" width="100%">
      <TemplateList
        setMessage={(t) => {
          props.onChange(t, true);
        }}
        disabled={props.disabledTemplate}
        isModalOpen={props.isModalOpen}
        setShowModal={props.setIsModalOpen}
      />
      {props.disabled ? (
        <div
          style={{
            border: props.assets.length ? "none" : "1px solid #8891AA",
            padding: "8px 12px",
            margin: `0 6px ${props.assets.length ? "12" : "0"}px 6px`,
            borderRadius: "4px",
            width: "100%",
            height: "auto",
            maxHeight: "300px",
            overflowY: "auto",
            color: props.message ? "inherit" : "#9CA3AF",
            whiteSpace: "pre-wrap",
            backgroundColor: "#f0f0f0", // Ajuste conforme necessário
          }}
        >
          {props.message ||
            "Atribua a conversa a você para poder enviar mensagens"}
        </div>
      ) : (
        <textarea
          onChange={(e) => {
            setCursorPostions(e.currentTarget.selectionStart ?? 0);
            props.onChange(e.currentTarget.value, props.disabled);
          }}
          onInput={(e) => {
            e.currentTarget.style.height = "auto";
            e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`; // Ajusta a altura com base no scrollHeight
          }}
          aria-describedby="message_help_text"
          id="message-input-shorter"
          name="message-input-shorter"
          value={props.message}
          autoFocus
          autoComplete="false"
          autoSave="false"
          placeholder="Adicione uma mensagem"
          style={{
            border: props.assets.length ? "none" : "1px solid #8891AA",
            padding: "8px 12px",
            margin: `0 6px ${props.assets.length ? "12" : "0"}px 6px`,
            borderRadius: "4px",
            width: "100%",
            height: "40px",
            minHeight: "40px",
            maxHeight: "300px",
            resize: "none",
            overflowY: "hidden",
          }}
          onFocus={(e) =>
            e.currentTarget.setSelectionRange(cursorPosition, cursorPosition)
          }
          onKeyPress={handleKeyPress}
        />
      )}

      {props.assets.length ? (
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {props.assets.map(({ name, size }) => (
            <MessageFile
              key={`${name + "_" + size}`}
              media={{ filename: name, size }}
              onRemove={() => props.onFileRemove(name + "_" + size)}
            />
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

export default MessageInput;
