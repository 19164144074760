import { Avatar } from "@twilio-paste/avatar";
import { Box, Text } from "@twilio-paste/core";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { UserIcon } from "@twilio-paste/icons/esm/UserIcon";
import { Menu, MenuButton, MenuItem, useMenuState } from "@twilio-paste/menu";
import { ConnectionState } from "@twilio/conversations";
import React, { useEffect, useMemo, useState } from "react";
import styles from "../styles";

import MedexLogo from "./icons/MedexLogo";
import { getMsgTemplates } from "../services/api";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../store";
import { ReduxTemplate } from "../store/reducers/msgTemplatesReducer";

type SetTokenType = (token: string) => void;

type AppHeaderProps = {
  user: string;
  onSignOut: () => void;
  connectionState: ConnectionState;
  setToken: SetTokenType;
};
const AppHeader: React.FC<AppHeaderProps> = ({
  user,
  onSignOut,
  connectionState,
}) => {
  const dispatch = useDispatch();
  const { setMsgTemplates } = bindActionCreators(actionCreators, dispatch);

  const menu = useMenuState();
  const [partner, setPartner] = useState("");
  const [partners, setPartners] = useState("".split(",") ?? []);

  const label: "online" | "connecting" | "offline" = useMemo(() => {
    switch (connectionState) {
      case "connected":
        return "online";
      case "connecting":
        return "connecting";
      default:
        return "offline";
    }
  }, [connectionState]);

  const changePartner = async (partner: string) => {
    localStorage.setItem("partner", partner);
    setPartner(partner);

    location.reload();
  };

  useEffect(() => {
    const partners = localStorage.getItem("partners");
    const partner = localStorage.getItem("partner");
    if (partners && partner) {
      setPartners(partners.split(","));
      setPartner(partner);
      getMsgTemplates(partner).then((res) => {
        const sortedItems = res?.items.sort(
          (a: ReduxTemplate, b: ReduxTemplate) => {
            const aName = a.medex_name || a.friendly_name;
            const bName = b.medex_name || b.friendly_name;
            return aName.localeCompare(bName);
          }
        );
        setMsgTemplates(sortedItems ?? []);
      });
    }
  }, []);

  return (
    <div style={styles.appHeader}>
      <div style={styles.flex}>
        <MedexLogo />
        <div style={styles.appLogoTitle}>Medex Conversas</div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 30,
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <select
            id="numproxies"
            onChange={(a) => changePartner(a.currentTarget.value)}
            value={partner}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="">Selecione o parceiro</option>
            {partners?.map((p, index) => (
              <option value={p} key={index}>
                {p}
              </option>
            ))}
          </select>
        </Box>
        <div style={styles.userTile}>
          <Avatar size="sizeIcon70" name="avatar example" icon={UserIcon} />
          <div
            style={{
              padding: "0 10px",
            }}
          >
            {user}
            <Text
              as="span"
              color={
                label === "online"
                  ? "colorTextIconAvailable"
                  : label === "connecting"
                  ? "colorTextIconBusy"
                  : "colorTextIconError"
              }
              style={{
                fontSize: "10px",
                display: "block",
                paddingTop: 5,
                lineHeight: 0,
              }}
            >
              {label === "online"
                ? "Online"
                : label === "connecting"
                ? "Connecting…"
                : "Offline"}
            </Text>
          </div>
          <MenuButton {...menu} variant="link" size="reset">
            <ChevronDownIcon
              color="colorTextInverse"
              decorative={false}
              title="Configurações"
            />
          </MenuButton>
          <Menu {...menu} aria-label="Preferences">
            <MenuItem {...menu} onClick={onSignOut}>
              Sair
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
